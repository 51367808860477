"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("./polyFills");

var _SprocketMenu = _interopRequireDefault(require("./SprocketMenu"));

var _PostFilterWidget = _interopRequireDefault(require("./PostFilterWidget"));

document.addEventListener('DOMContentLoaded', function init() {
  var menu = new _SprocketMenu.default(window.hsVars);
  menu.showToolsMenuIfAuthor();
  var postFilterWidget = new _PostFilterWidget.default();
  postFilterWidget.setup();
  document.removeEventListener('DOMContentLoaded', init);
}, false);